import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { EmployeeData } from './employeeDetail/EmployeeDetail.screen'
import { PayrollAdjustmentData } from './employeeDetail/content/EmployeePayrollAdjustment.screen'
import { ACTIVE_CONTRACT_MODAL } from './lib'
import { ContractRegularWorkingDaysValidationSchema } from './modal/contract/ContractRegularWorkingDaysModal'
import { ContractDays } from './modal/contract/ContractRestDaysModal'
import { ContractSalaryAllowancesValidationSchema } from './modal/contract/ContractSalaryAllowancesModal'
import { ContractStartDateValidationSchema } from './modal/contract/ContractStartDateModal'
import { ContractShiftScheduleInput } from '../../__generated__/schema'
import { RootState } from '../../redux/slices'
import { MasterContract } from '../../types/MasterContractsType'

export type PayrollAdjustmentType = 'ADDITION' | 'DEDUCTION'

type State = {
  isShowEmployeeForm: boolean
  isShowEmployeePayrollDetail: boolean
  isShowEmployeeLeaveForm: boolean
  isShowEmployeeSalaryForm: boolean
  isShowPayrollAdjustmentConfirmation: boolean
  isShowOffboardedDateForm: boolean
  employeeData: EmployeeData | null
  showContractCreationModal: ACTIVE_CONTRACT_MODAL | null
  showContractModal: boolean
  contractStartDate: ContractStartDateValidationSchema | null
  contractSalaryAllowance: ContractSalaryAllowancesValidationSchema | null
  contractRegularWorkingDays: ContractRegularWorkingDaysValidationSchema['customTime']
  employeeWorkingDays: ContractDays[]
  masterContract: MasterContract | null
  isModifyContract: boolean
  contractShiftDay: ContractShiftScheduleInput | null
  contractFileId: string
  deleteFileLoading: boolean
  selectedTab: number
  payrollAdjustmentData: PayrollAdjustmentData | null
  payrollAdjustmentId: string
  isAddition: boolean
  isRefetch: boolean
  isRefetchContractList: boolean
  payrollAdjustmentType: PayrollAdjustmentType | null
  employeeName: string
}

export const initialState: State = {
  isShowEmployeeForm: false,
  isShowEmployeePayrollDetail: false,
  isShowEmployeeLeaveForm: false,
  isShowOffboardedDateForm: false,
  employeeData: null,
  showContractModal: false,
  showContractCreationModal: null,
  contractStartDate: null,
  contractSalaryAllowance: null,
  contractRegularWorkingDays: [],
  employeeWorkingDays: [],
  masterContract: null,
  isShowPayrollAdjustmentConfirmation: false,
  isModifyContract: false,
  contractShiftDay: null,
  deleteFileLoading: false,
  contractFileId: '',
  isShowEmployeeSalaryForm: false,
  selectedTab: 0,
  payrollAdjustmentData: null,
  payrollAdjustmentId: '',
  isAddition: false,
  isRefetch: false,
  isRefetchContractList: false,
  payrollAdjustmentType: null,
  employeeName: ''
}

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setShowEmployeeForm(
      state,
      {
        payload: { showEmployeeForm }
      }: { payload: { showEmployeeForm: boolean } }
    ) {
      state.isShowEmployeeForm = showEmployeeForm
    },
    setEmployeeData(
      state,
      {
        payload: { employeeData }
      }: { payload: { employeeData: EmployeeData | null } }
    ) {
      state.employeeData = employeeData
    },
    setShowPayrollDetail(
      state,
      action: PayloadAction<{ showEmployeePayrollDetail: boolean }>
    ) {
      state.isShowEmployeePayrollDetail =
        action.payload.showEmployeePayrollDetail
    },
    setShowEmployeeLeaveForm(
      state,
      action: PayloadAction<{ showEmployeeLeaveForm: boolean }>
    ) {
      state.isShowEmployeeLeaveForm = action.payload.showEmployeeLeaveForm
    },
    setClearContractValueCreation(state) {
      state.showContractCreationModal = null
      state.showContractModal = false
      state.contractStartDate = null
      state.contractSalaryAllowance = null
      state.contractRegularWorkingDays = []
      state.employeeWorkingDays = []
      state.masterContract = null
      state.contractShiftDay = null
    },
    setShowContractCreationModal(
      state,
      action: PayloadAction<{
        showContractCreationModal: ACTIVE_CONTRACT_MODAL | null
      }>
    ) {
      state.showContractCreationModal = action.payload.showContractCreationModal
    },
    setShowContractModal(
      state,
      action: PayloadAction<{ showContractModal: boolean }>
    ) {
      state.showContractModal = action.payload.showContractModal
    },
    setContractStartDate(
      state,
      action: PayloadAction<{
        contractStartDate: ContractStartDateValidationSchema | null
      }>
    ) {
      state.contractStartDate = action.payload.contractStartDate
    },
    setContractSalaryAllowance(
      state,
      action: PayloadAction<{
        contractSalaryAllowance: ContractSalaryAllowancesValidationSchema | null
      }>
    ) {
      state.contractSalaryAllowance = action.payload.contractSalaryAllowance
    },
    setContractRegularWorkingDays(
      state,
      action: PayloadAction<{
        contractRegularWorkingDays: ContractRegularWorkingDaysValidationSchema['customTime']
      }>
    ) {
      state.contractRegularWorkingDays =
        action.payload.contractRegularWorkingDays

      const regularWorkingDays =
        action.payload.contractRegularWorkingDays.reduce(
          (workingDays, time, i) => {
            if (time.startTime && time.endTime) {
              const regularWorkingDay: ContractDays = {
                startTime: time.startTime,
                endTime: time.endTime,
                restDay: false,
                day: i
              }
              workingDays.push(regularWorkingDay)
            }

            return workingDays
          },
          [] as ContractDays[]
        )

      const selectedDays = regularWorkingDays.map((time) => time.day)
      const restTime = state.employeeWorkingDays.filter(
        (workingDay) =>
          workingDay.restDay && !selectedDays.includes(workingDay.day)
      )

      state.employeeWorkingDays = [...restTime, ...regularWorkingDays]
    },
    setEmployeeWorkingDays(
      state,
      { payload }: PayloadAction<{ employeeWorkingDays: ContractDays[] }>
    ) {
      state.employeeWorkingDays = payload.employeeWorkingDays
    },
    setMasterContract(
      state,
      action: PayloadAction<{ masterContract?: MasterContract }>
    ) {
      state.masterContract = action.payload.masterContract ?? null
    },
    setShowEmployeeSalaryForm(
      state,
      action: PayloadAction<{
        showEmployeeSalaryForm: boolean
        payrollAdjustmentType: PayrollAdjustmentType | null
      }>
    ) {
      state.isShowEmployeeSalaryForm = action.payload.showEmployeeSalaryForm
      state.payrollAdjustmentType = action.payload.payrollAdjustmentType
    },
    setPayrollAdjustmentConfirmation(
      state,
      action: PayloadAction<{
        showPayrollAdjustmentConfirmation: boolean
      }>
    ) {
      state.isShowPayrollAdjustmentConfirmation =
        action.payload.showPayrollAdjustmentConfirmation
    },
    setIsModifyContract(
      state,
      action: PayloadAction<{ isModifyContract: boolean }>
    ) {
      state.isModifyContract = action.payload.isModifyContract
    },
    setContractShiftDay(
      state,
      action: PayloadAction<{
        contractShiftDay: ContractShiftScheduleInput | null
      }>
    ) {
      state.contractShiftDay = action.payload.contractShiftDay
    },
    setContractFileId(
      state,
      action: PayloadAction<{ contractFileId: string }>
    ) {
      state.contractFileId = action.payload.contractFileId
    },
    setDeleteFileLoading(
      state,
      action: PayloadAction<{ deleteFileLoading: boolean }>
    ) {
      state.deleteFileLoading = action.payload.deleteFileLoading
    },
    setSelectedTab(
      state,
      { payload: { selectedTab } }: { payload: { selectedTab: number } }
    ) {
      state.selectedTab = selectedTab
    },
    setPayrollAdjustmentData(
      state,
      action: PayloadAction<{
        payrollAdjustmentData: PayrollAdjustmentData | null
      }>
    ) {
      state.payrollAdjustmentData = action.payload.payrollAdjustmentData
    },
    setPayrollAdjustmentId(
      state,
      action: PayloadAction<{ payrollAdjustmentId: string }>
    ) {
      state.payrollAdjustmentId = action.payload.payrollAdjustmentId
    },
    setIsAddition(state, action: PayloadAction<{ isAddition: boolean }>) {
      state.isAddition = action.payload.isAddition
    },
    setIsRefetch(state, action: PayloadAction<{ isRefetch: boolean }>) {
      state.isRefetch = action.payload.isRefetch
    },
    setIsRefetchContractList(
      state,
      action: PayloadAction<{ isRefetchContractList: boolean }>
    ) {
      state.isRefetch = action.payload.isRefetchContractList
    },
    setEmployeeName(state, action: PayloadAction<{ employeeName: string }>) {
      state.employeeName = action.payload.employeeName
    },
    setShowOffboardedEmployeeModal(
      state,
      action: PayloadAction<{ showOffboardedDateForm: boolean }>
    ) {
      state.isShowOffboardedDateForm = action.payload.showOffboardedDateForm
    }
  }
})

export const showEmployeeFormSelector = (state: RootState): boolean =>
  state.employeeReducer.isShowEmployeeForm

export const employeeDataSelector = (state: RootState): EmployeeData | null =>
  state.employeeReducer.employeeData

export const showEmployeePayrollDetailSelector = (state: RootState): boolean =>
  state.employeeReducer.isShowEmployeePayrollDetail

export const showEmployeeLeaveFormSelector = (state: RootState): boolean =>
  state.employeeReducer.isShowEmployeeLeaveForm

export const showContractCreationModalSelector = (
  state: RootState
): ACTIVE_CONTRACT_MODAL | null =>
  state.employeeReducer.showContractCreationModal

export const showContractModalSelector = (state: RootState): boolean =>
  state.employeeReducer.showContractModal

export const contractStartDateSelector = (
  state: RootState
): ContractStartDateValidationSchema | null =>
  state.employeeReducer.contractStartDate

export const contractSalaryAllowanceSelector = (
  state: RootState
): ContractSalaryAllowancesValidationSchema | null =>
  state.employeeReducer.contractSalaryAllowance

export const contractRegularWorkingDaysSelector = (
  state: RootState
): ContractRegularWorkingDaysValidationSchema['customTime'] =>
  state.employeeReducer.contractRegularWorkingDays

export const employeeWorkingDaysSelector = (state: RootState): ContractDays[] =>
  state.employeeReducer.employeeWorkingDays

export const masterContractSelector = (
  state: RootState
): MasterContract | null => state.employeeReducer.masterContract

export const showEmployeeSalaryFormSelector = (state: RootState): boolean =>
  state.employeeReducer.isShowEmployeeSalaryForm

export const showPayrollAdjustmentConfirmationSelector = (
  state: RootState
): boolean => state.employeeReducer.isShowPayrollAdjustmentConfirmation

export const isModifySelector = (state: RootState): boolean =>
  state.employeeReducer.isModifyContract

export const contractShiftDaySelector = (
  state: RootState
): ContractShiftScheduleInput | null => state.employeeReducer.contractShiftDay

export const contractFileIdSelector = (state: RootState): string =>
  state.employeeReducer.contractFileId

export const deleteFileLoadingSelector = (state: RootState): boolean =>
  state.employeeReducer.deleteFileLoading

export const employeeSelectedTabSelector = (state: RootState): number =>
  state.employeeReducer.selectedTab

export const payrollAdjustmentDataSelector = (
  state: RootState
): PayrollAdjustmentData | null => state.employeeReducer.payrollAdjustmentData

export const payrollAdjustmentIdSelector = (state: RootState): string =>
  state.employeeReducer.payrollAdjustmentId

export const isAdditionSelector = (state: RootState): boolean =>
  state.employeeReducer.isAddition

export const isRefetchSelector = (state: RootState): boolean =>
  state.employeeReducer.isRefetch

export const payrollAdjustmentTypeSelector = (
  state: RootState
): PayrollAdjustmentType | null => state.employeeReducer.payrollAdjustmentType

export const employeeNameSelector = (state: RootState): string =>
  state.employeeReducer.employeeName

export const isShowOffboardedDateFormSelector = (state: RootState): boolean =>
  state.employeeReducer.isShowOffboardedDateForm

export const isRefetchContractListSelector = (state: RootState): boolean =>
  state.employeeReducer.isRefetchContractList

export const { name, actions: employeeActions, reducer } = employeeSlice
