import Box from '@luce/ui-kit/components/base/Box'
import IconButton from '@luce/ui-kit/components/button/IconButton'
import { MenuButton } from '@luce/ui-kit/components/button/MenuButton'
import SideNav from '@luce/ui-kit/components/molecules/SideNav'
import { Theme, makeStyles } from '@luce/ui-kit/themes/ThemeProvider'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  EmployeesMenuButton,
  EmployeeLeaves,
  LeaveRequestsMenuButton,
  PayrollsMenuButton,
  ProfileMenuButton
} from './MenuButtonConstant'
import { UserTypeEnum } from '../../__generated__/schema'
import useAuth from '../../hooks/AuthHook'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: '1200 !important' as any,
    '& .MuiBackdrop-root': {
      background: 'transparent'
    },
    '& .MuiDrawer-paperAnchorLeft': {
      paddingTop: theme.spacing(10)
    }
  },
  icon: {
    color: theme.palette.common.white
  }
}))

export type ActivePageRoute =
  | 'profile'
  | 'leave-requests'
  | 'payrolls'
  | 'employees'
  | 'employee-leaves'

interface Props {
  onLogout(): void
  activePageRoute: ActivePageRoute
}

const NavigationDrawer: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const auth = useAuth()
  const user = auth?.user
  const { onLogout, activePageRoute } = props
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false)

  const toggleDrawer = (open: boolean): void => {
    setIsNavbarOpen(open)
  }

  const handleLogout = (): void => {
    setIsNavbarOpen(false)
    onLogout()
  }

  const handleClickMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    event.preventDefault()
  }

  const handleNavigation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url?: string,
    expand?: boolean
  ): void => {
    event.preventDefault()

    if (expand && !url) {
      return
    }

    navigate(`/${url}`)
    toggleDrawer(false)
  }

  const MenusNav: MenuButton[] = []
  if (user?.type === UserTypeEnum.SUPERADMIN) {
    MenusNav.push(
      LeaveRequestsMenuButton,
      EmployeeLeaves,
      PayrollsMenuButton,
      EmployeesMenuButton
    )
  } else if (user?.type === UserTypeEnum.ADMIN) {
    MenusNav.push(
      ProfileMenuButton,
      LeaveRequestsMenuButton,
      EmployeeLeaves,
      PayrollsMenuButton,
      EmployeesMenuButton
    )
  } else if (user?.type === UserTypeEnum.MANAGER) {
    MenusNav.push(ProfileMenuButton, LeaveRequestsMenuButton, EmployeeLeaves)
  } else if (user?.type === UserTypeEnum.STAFF) {
    MenusNav.push(ProfileMenuButton, EmployeeLeaves)
  }

  return (
    <Box>
      <SideNav
        children={
          isNavbarOpen ? (
            <IconButton
              className={classes.icon}
              iconName="CloseIcon"
              onClick={() => toggleDrawer(false)}
            />
          ) : (
            <IconButton
              className={classes.icon}
              iconName="MenuIcon"
              onClick={() => toggleDrawer(true)}
            />
          )
        }
        menus={MenusNav}
        isOpen={isNavbarOpen}
        toggleClick={() => toggleDrawer(false)}
        onClick={handleClickMenu}
        className={classes.root}
        activeRoute={activePageRoute}
        logOut={handleLogout}
        urlOnClick={handleNavigation}
      />
    </Box>
  )
}

export default NavigationDrawer
