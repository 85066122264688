import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@luce/ui-kit/components/atom/CssBaseline'
import { PickersUtilsProvider } from '@luce/ui-kit/components/atom/Pickers'
import ThemeProvider from '@luce/ui-kit/themes/ThemeProvider'
import theme from '@luce/ui-kit/themes/default'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'

import App from './App'
import globalStore from './redux/ConfigureStore'
import reportWebVitals from './reportWebVitals'
import * as Sentry from './services/sentry'

import './index.css'

export const store = globalStore()

Sentry.init()

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <PickersUtilsProvider utils={DateFnsUtils}>
        <ReduxProvider store={store}>
          <CssBaseline />
          <App />
        </ReduxProvider>
      </PickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
