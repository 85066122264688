import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { getEnv, isLocal } from '../utils/helpers'

export function init(): void {
  if (isLocal()) {
    return
  }

  const { sentryDsn: dsn, env } = getEnv()

  if (dsn) {
    Sentry.init({
      environment: env,
      dsn,
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0
    })
  }
}
