import { ServerError } from '@apollo/client'
// import LoadingOverlay from 'components/loader/LoadingOverlay'
import { gql } from 'graphql.macro'
import React, {
  createContext,
  ReactNode,
  Suspense,
  useContext,
  useEffect,
  useState
} from 'react'

import { apolloClient } from '../../services/apollo'
import LoadingOverlay from '../../ui/LoadingOverlay'

type MaintenanceModeContextType = {
  setMaintenance(active: boolean): void
  isMaintenance: boolean
}

export const MAINTENANCE_MODE_KEY = 'maintenanceMode'

const QUERY = gql`
  {
    __schema {
      __typename
    }
  }
`

const MaintenancePage = React.lazy(() => import('../../pages/MaintenancePage'))

const MaintenanceModeContext = createContext<MaintenanceModeContextType>({
  setMaintenance: () => {},
  isMaintenance: false
})

type MaintenanceModeProviderProps = {
  children: ReactNode
}

const MaintenanceModeProvider: React.FC<MaintenanceModeProviderProps> = ({
  children
}) => {
  const [isMaintenance, setMaintenance] = useState(
    localStorage.getItem(MAINTENANCE_MODE_KEY) === 'true'
  )

  const pingCheckGraphqlAPI = (): void => {
    apolloClient
      .query({ query: QUERY })
      .then(() => {
        localStorage.removeItem(MAINTENANCE_MODE_KEY)
        setMaintenance(false)
      })
      .catch(({ networkError }: { networkError: ServerError }) => {
        if (
          networkError.statusCode === 503 ||
          networkError.statusCode === 504
        ) {
          console.log(`[Maintenance Mode]: ${networkError.response.statusText}`)
          localStorage.setItem(MAINTENANCE_MODE_KEY, 'true')
          setMaintenance(true)
        }
      })
  }

  useEffect(() => {
    let timeoutMaintenanceId: string | number | NodeJS.Timeout | undefined
    pingCheckGraphqlAPI()

    if (isMaintenance) {
      timeoutMaintenanceId = setInterval(pingCheckGraphqlAPI, 5000)
    }

    return () => clearInterval(timeoutMaintenanceId)
  }, [])

  return (
    <MaintenanceModeContext.Provider
      value={{
        isMaintenance,
        setMaintenance
      }}
    >
      {isMaintenance ? (
        <Suspense fallback={<LoadingOverlay />}>
          <MaintenancePage />
        </Suspense>
      ) : (
        children
      )}
    </MaintenanceModeContext.Provider>
  )
}

function useMaintenanceContext(): MaintenanceModeContextType {
  const context = useContext(MaintenanceModeContext)
  if (context === undefined) {
    throw new Error(
      'MaintenanceModeContext must be used within a MaintenanceModeProvider'
    )
  }
  return context
}

export { MaintenanceModeProvider, useMaintenanceContext }
