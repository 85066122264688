import { MenuButton } from '@luce/ui-kit/components/button/MenuButton'

const ProfileMenuButton: MenuButton = {
  icon: 'ProfileIcon',
  label: 'Profile',
  url: 'profile',
  isExpand: false
}

const LeaveRequestsMenuButton: MenuButton = {
  icon: 'CalendarOffIcon',
  label: 'Leave Requests',
  url: 'leave-requests',
  isExpand: false
}

const PayrollsMenuButton: MenuButton = {
  icon: 'PaymentIcon',
  label: 'Payrolls',
  url: 'payrolls',
  isExpand: false
}

const EmployeesMenuButton: MenuButton = {
  icon: 'PeopleIcon',
  label: 'Employees',
  url: 'employees',
  isExpand: false
}

const EmployeeLeaves: MenuButton = {
  icon: 'OnefaceIcon',
  label: 'Employee Leaves',
  url: 'employee-leaves',
  isExpand: false
}

// const EmployeeResignation: MenuButton = {
//   icon: 'AccessInfoIcon',
//   label: 'Employee Resignation',
//   url: 'employee-resignation',
//   isExpand: false
// }

export {
  ProfileMenuButton,
  LeaveRequestsMenuButton,
  PayrollsMenuButton,
  EmployeesMenuButton,
  EmployeeLeaves
}
