import Backdrop from '@luce/ui-kit/components/atom/Backdrop'
import CircularProgress from '@luce/ui-kit/components/atom/CircularProgress'
import React, { ReactNode } from 'react'

import Session, { UserSession, userSessionKey } from './UserSession'
import { useSignOutMutation } from '../../__generated__/hooks'
import {
  getJwtFromRefreshToken,
  setJWT,
  setLogoutCallback
} from '../../services/apollo'
import { preventSilentSignin } from '../../utils/helpers'

interface Props {
  children: ReactNode
}

export interface User {
  id: string
  employeeId: string
  firstName: string
  lastName: string
  type?: string
}

export interface AuthInterface {
  user?: User | null | undefined
  logout(): void
  loggedIn(user: User, jwt?: string, jwtExpiry?: number): void
  loadingSession: boolean
  isSigningOut: boolean
}

const restoredSession = Session.currentSession()

const AuthContext = React.createContext<AuthInterface | undefined>(undefined)

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [session, setSession] = React.useState<UserSession>(restoredSession)
  const [isSigningOut, setIsSigningOut] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [signoutMutation] = useSignOutMutation()

  const clearSession = () => {
    const session = Session.logout()
    setSession(session)
  }

  const logout = React.useCallback(async () => {
    preventSilentSignin()
    setIsSigningOut(true)
    try {
      await signoutMutation()
    } catch (err) {
      console.log(err)
    } finally {
      setJWT(undefined)
      clearSession()
      setIsSigningOut(false)
    }
  }, [])

  const loggedIn = React.useCallback(
    (user: User, jwt?: string, jwtExpiry?: number) => {
      if (jwt) {
        setJWT(jwt)
      }
      const session = Session.login(user)
      console.log('Logged In. Session = ', session)
      setSession(session)
    },
    []
  )

  const checkSession = async (session: UserSession) => {
    if (session.user) {
      try {
        await getJwtFromRefreshToken()
      } catch (err: any) {
        if (err.status === 403 || err.status === 404) {
          const session = Session.logout()
          setSession(session)
        }
      }
    }
    setIsLoading(false)
  }

  const user = session?.user

  const onStorage = React.useCallback(
    (e: StorageEvent) => {
      if (e.key === userSessionKey) {
        console.log('oldValue', e.oldValue)
        console.log('newValue', e.newValue)
        if (e.oldValue && !e.newValue) {
          console.log('User logged out on another tab')
          setJWT(undefined)
          const session = Session.currentSession()
          setSession(session)
        } else if (!e.oldValue && e.newValue) {
          console.log('User logged in on another tab')
        }
      }
    },
    [user]
  )

  React.useEffect(() => {
    checkSession(session)
    setLogoutCallback(clearSession)
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loadingSession: isLoading,
        user: session.user,
        logout,
        loggedIn,
        isSigningOut
      }}
    >
      {children}
      <Backdrop open={isSigningOut || isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  )
}

export default AuthContext
