import Box from '@luce/ui-kit/components/base/Box'
import Typography from '@luce/ui-kit/components/base/Typography'
import Button from '@luce/ui-kit/components/button/Button'
import LuceAppBar from '@luce/ui-kit/components/molecules/AppBar'
import { makeStyles, Theme } from '@luce/ui-kit/themes/ThemeProvider'
import LuceTheme from '@luce/ui-kit/themes/default'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'

import NavigationDrawer, { ActivePageRoute } from './NavigationDrawer'
import { UserTypeEnum } from '../../__generated__/schema'
import useAuth from '../../hooks/AuthHook'
import { actions } from '../../redux/slices/modalSlice'
import AvatarPreview from '../../ui/Avatar'
import { getAvatarLetter } from '../../utils/helpers'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1300,
    position: 'relative'
  },
  title: {
    marginRight: theme.spacing(2)
  },
  placeholder: {
    flexGrow: 1
  },
  linkStyle: {
    textDecoration: 'none',
    color: theme.palette.common.white
  },
  avatar: {
    ...LuceTheme.typography.overline,
    color: LuceTheme.palette.primary.main,
    background: LuceTheme.palette.grey[400],
    marginRight: theme.spacing(1.5),
    textDecoration: 'none',
    '&:hover': {
      background: LuceTheme.palette.grey[600]
    }
  }
}))

type ActivePageName =
  | 'Luce'
  | 'Profile'
  | 'Leave Requests'
  | 'Payrolls'
  | 'Employees'
  | 'Employee Leaves'

const locationPathNames: { [key in ActivePageRoute]: ActivePageName } = {
  profile: 'Profile',
  'leave-requests': 'Leave Requests',
  payrolls: 'Payrolls',
  employees: 'Employees',
  'employee-leaves': 'Employee Leaves'
}

interface Props {}

const AppBar: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const user = auth?.user
  const location = useLocation()
  const [activePage, setIsActivePage] = useState<ActivePageName>('Luce')
  const [activePageRoute, setIsActivePageRoute] = useState<ActivePageRoute>(
    user?.type === UserTypeEnum.SUPERADMIN ? 'employees' : 'profile'
  )

  const isValidLocation = (
    value: string
  ): value is keyof typeof locationPathNames => {
    return value in locationPathNames
  }

  useEffect(() => {
    const pathName = location.pathname.slice(1)
    if (isValidLocation(pathName)) {
      setIsActivePage(locationPathNames[pathName])
      setIsActivePageRoute(pathName)
    }
  }, [location.pathname])

  const onLogout = async (): Promise<void> => {
    await auth?.logout()
    navigate('/', { replace: true })
  }

  const showProfileModal = () => {
    dispatch(actions.showModal())
  }

  const classes = useStyles()
  return (
    <LuceAppBar className={classes.root} position="static">
      {user ? (
        <Box display="flex" alignItems="center">
          <NavigationDrawer
            onLogout={onLogout}
            activePageRoute={activePageRoute}
          />
          <Typography>{activePage}</Typography>
        </Box>
      ) : (
        <RouterLink className={classes.linkStyle} to="/login">
          <Button variants="primary">Login</Button>
        </RouterLink>
      )}
      <div className={classes.placeholder} />
      {user && (
        <>
          <Box display="none">
            <Button variant="contained" onClick={showProfileModal}>
              Show Profile
            </Button>
          </Box>
          <Box
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {}}
            color="inherit"
          >
            <RouterLink className={classes.linkStyle} to="/profile">
              <AvatarPreview
                customSize="medium"
                avatarLetter={getAvatarLetter(user)}
                className={classes.avatar}
              />
            </RouterLink>
          </Box>
          <RouterLink className={classes.linkStyle} to="/profile">
            <Typography variant="subtitle1">{user.firstName}</Typography>
          </RouterLink>
        </>
      )}
    </LuceAppBar>
  )
}

export default AppBar
