import { configureStore } from '@reduxjs/toolkit'
import { StoreEnhancer } from 'redux'
import { forceReducerReload } from 'redux-injectors'
import { logger } from 'redux-logger'

import appReducer from './slices'

export default function ConfigureAppStore(initialState = {}) {
  const middlewares = process.env.NODE_ENV === 'test' ? [] : [logger]

  const enhancers: StoreEnhancer[] = []
  const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), ...middlewares],
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers
  })

  // @ts-ignore
  if (module.hot) {
    // @ts-ignore
    module.hot.accept('./slices', () => {
      forceReducerReload(store)
    })
  }

  return store
}
