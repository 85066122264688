export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
  DateTime: any
  UploadedFile: any
}

export type AdminUser = {
  __typename?: 'AdminUser'
  email: Scalars['String']
  employeeId: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type: UserTypeEnum
}

export enum BankNameEnum {
  BCA = 'BCA',
  BDOUNIBANKINC = 'BDOUnibankInc',
  BNI = 'BNI',
  BRI = 'BRI',
  BANKOFTHEPHILIPPINEISLANDS = 'BankofthePhilippineIslands',
  CTBCBANKPHILIPPINESCORP = 'CTBCBankPhilippinesCorp',
  DBS = 'DBS',
  EASTWESTBANKINGCORPORATION = 'EastWestBankingCorporation',
  MANDIRI = 'Mandiri',
  MAYBANK = 'Maybank',
  METROBANK = 'MetroBank',
  OCBC = 'OCBC',
  POSB = 'POSB',
  UOB = 'UOB',
  UNIONBANKOFTHEPHILIPPINES = 'UnionBankofthePhilippines'
}

export type Contract = {
  __typename?: 'Contract'
  annualLeave: Scalars['Int']
  contractDays?: Maybe<ContractDay[]>
  contractDetails: ContractDetail[]
  contractFiles?: Maybe<SavedFile[]>
  contractShiftSchedules?: Maybe<ContractShiftSchedule[]>
  employee: Employee
  employeeId: Scalars['ID']
  endDate?: Maybe<Scalars['String']>
  hospitalLeave: Scalars['Int']
  id: Scalars['ID']
  masterContract: MasterContract
  masterContractId: Scalars['ID']
  payrollPeriod: PayrollPeriodEnum
  probationPeriod: Scalars['Int']
  probationStatus: ProbationStatusEnum
  publicHolidaySchedule: CountryEnum
  sickLeave: Scalars['Int']
  startDate: Scalars['String']
  status: StatusEnum
}

export type ContractDay = {
  __typename?: 'ContractDay'
  contractId: Scalars['ID']
  day: Scalars['Int']
  endTime?: Maybe<Scalars['String']>
  id: Scalars['ID']
  startTime: Scalars['String']
}

export type ContractDaysInput = {
  day: Scalars['Int']
  endTime: Scalars['String']
  startTime: Scalars['String']
}

export type ContractDetail = {
  __typename?: 'ContractDetail'
  allowance: Scalars['Int']
  basicSalary: Scalars['Int']
  contractId: Scalars['ID']
  employmentLevel: EmploymentLevelEnum
  endDate?: Maybe<Scalars['String']>
  entitledToCommission: Scalars['Boolean']
  id: Scalars['ID']
  nonTaxableAllowance?: Maybe<Scalars['Int']>
  startDate: Scalars['String']
  version: Scalars['Int']
}

export type ContractShiftSchedule = {
  __typename?: 'ContractShiftSchedule'
  contractId: Scalars['ID']
  date: Scalars['String']
  endTime?: Maybe<Scalars['String']>
  id: Scalars['ID']
  startTime: Scalars['String']
}

export type ContractShiftScheduleInput = {
  date: Scalars['Date']
  endTime?: InputMaybe<Scalars['String']>
  startTime: Scalars['String']
}

export enum ContractSortByEnum {
  CREATED_AT = 'created_at',
  START_DATE = 'start_date',
  STATUS = 'status',
  UPDATED_AT = 'updated_at'
}

export type ContractsByFilter = {
  employeeId?: InputMaybe<Scalars['ID'][]>
  id?: InputMaybe<Scalars['ID'][]>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  sort?: InputMaybe<SortEnum[]>
  sortBy?: InputMaybe<ContractSortByEnum[]>
  status?: InputMaybe<StatusEnum>
}

export type ContractsByFilters = {
  __typename?: 'ContractsByFilters'
  contracts: Contract[]
  count: Scalars['Int']
}

export type CsvErrors = {
  __typename?: 'CsvErrors'
  error: Scalars['String']
  row?: Maybe<Scalars['String']>
  rowIndex?: Maybe<Scalars['Int']>
}

/** Input for device-specific data */
export type DeviceInput = {
  appVersion?: InputMaybe<Scalars['String']>
  locale?: InputMaybe<Scalars['String']>
  platform?: InputMaybe<Scalars['String']>
  platformVersion?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<Scalars['String']>
  uid?: InputMaybe<Scalars['String']>
}

export type Employee = {
  __typename?: 'Employee'
  address: Scalars['String']
  avatarUrl?: Maybe<Scalars['String']>
  bankAccountNumber: Scalars['String']
  bankName: BankNameEnum
  contracts: Contract[]
  dateOfBirth: Scalars['String']
  department: EmployeeDepartmentEnum
  email: Scalars['String']
  emergencyContactName?: Maybe<Scalars['String']>
  emergencyContactNumber?: Maybe<Scalars['String']>
  emergencyContactRelationship?: Maybe<EmployeeRelationshipEnum>
  firstName: Scalars['String']
  fullNameOnId: Scalars['String']
  id: Scalars['ID']
  idCardNumber: Scalars['String']
  jobTitle: Scalars['String']
  lastName: Scalars['String']
  lastWorkingDay?: Maybe<Scalars['String']>
  leaveAccount: LeaveAccount
  manager?: Maybe<ManagerUser>
  managerId?: Maybe<Scalars['String']>
  maritalStatus: EmployeeMaritalStatusEnum
  nationality: NationalityEnum
  numberOfChildren?: Maybe<Scalars['Int']>
  phoneNumber: Scalars['String']
  religion?: Maybe<EmployeeReligionEnum>
  status: EmployeeStatusEnum
  taxType?: Maybe<EmployeeTaxTypeEnum>
  user: StaffUser
  workEmail: Scalars['String']
}

export type EmployeeByFilters = {
  __typename?: 'EmployeeByFilters'
  count: Scalars['Int']
  employees: Employee[]
}

export type EmployeeByFiltersInput = {
  department?: InputMaybe<EmployeeDepartmentEnum[]>
  id?: InputMaybe<Scalars['ID'][]>
  limit?: InputMaybe<Scalars['Int']>
  managerId?: InputMaybe<Scalars['ID'][]>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<SortEnum[]>
  sortBy?: InputMaybe<EmployeeSortByEnum[]>
  status?: InputMaybe<EmployeeStatusEnum[]>
}

export enum EmployeeDepartmentEnum {
  ACCOUNTING = 'ACCOUNTING',
  CS = 'CS',
  ENGINEERING = 'ENGINEERING',
  FINANCE = 'FINANCE',
  HR = 'HR',
  MARKETING = 'MARKETING',
  OPERATIONS = 'OPERATIONS',
  OTHERS = 'OTHERS',
  SALES = 'SALES',
  STRATEGY_AND_PLANNING = 'STRATEGY_AND_PLANNING'
}

export enum EmployeeMaritalStatusEnum {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  WIDOWED = 'WIDOWED'
}

export enum EmployeeRelationshipEnum {
  FAMILY_IN_LAW = 'FAMILY_IN_LAW',
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  OTHER = 'OTHER',
  PARTNER = 'PARTNER',
  SIBLING = 'SIBLING'
}

export enum EmployeeReligionEnum {
  ATHEISM = 'ATHEISM',
  BUDDHISM = 'BUDDHISM',
  CATHOLIC = 'CATHOLIC',
  CHRISTIAN = 'CHRISTIAN',
  HINDUISM = 'HINDUISM',
  MOESLEM = 'MOESLEM',
  OTHER = 'OTHER',
  TAOISM = 'TAOISM'
}

export enum EmployeeSortByEnum {
  CREATED_AT = 'created_at',
  DEPARTMENT = 'department',
  FIRST_NAME = 'first_name',
  FULL_NAME_ON_ID = 'full_name_on_id',
  LAST_NAME = 'last_name',
  STATUS = 'status',
  UPDATED_AT = 'updated_at'
}

export enum EmployeeStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum EmployeeTaxTypeEnum {
  K0 = 'K0',
  K1 = 'K1',
  K2 = 'K2',
  K3 = 'K3',
  TK0 = 'TK0',
  TK1 = 'TK1',
  TK2 = 'TK2',
  TK3 = 'TK3'
}

/** Input for current installation (= device) */
export type InstallationInput = {
  device?: InputMaybe<DeviceInput>
}

export type LeaveAccount = {
  __typename?: 'LeaveAccount'
  annualLeaveAccrued: Scalars['Float']
  annualLeaveBalance: Scalars['Float']
  annualLeaveRollover: Scalars['Float']
  annualLeaveTaken: Scalars['Float']
  hospitalLeaveAccrued: Scalars['Float']
  hospitalLeaveBalance: Scalars['Float']
  hospitalLeaveTaken: Scalars['Float']
  id: Scalars['ID']
  noPayLeaveTaken: Scalars['Float']
  otherPaidLeaveTaken: Scalars['Float']
  sickLeaveAccrued: Scalars['Float']
  sickLeaveBalance: Scalars['Float']
  sickLeaveTaken: Scalars['Float']
  wfhAccrued: Scalars['Float']
  wfhBalance: Scalars['Float']
  wfhTaken: Scalars['Float']
  worker: Employee
}

export type LeaveApplication = {
  __typename?: 'LeaveApplication'
  createdAt: Scalars['String']
  daysApplied: Scalars['Float']
  employee: Employee
  employeeId: Scalars['ID']
  endDate: Scalars['String']
  id: Scalars['ID']
  leaveApplicationFiles?: Maybe<SavedFile[]>
  leaveType: LeaveApplicationTypeEnum
  proofUrl?: Maybe<Scalars['String']>
  reason?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  status: LeaveApplicationStatusEnum
  updatedAt: Scalars['String']
}

export type LeaveApplicationByFilters = {
  __typename?: 'LeaveApplicationByFilters'
  count: Scalars['Int']
  leaveApplications: LeaveApplication[]
}

export type LeaveApplicationByFiltersInput = {
  department?: InputMaybe<EmployeeDepartmentEnum[]>
  employeeId?: InputMaybe<Scalars['ID'][]>
  endActiveDate?: InputMaybe<Scalars['Date']>
  endDate?: InputMaybe<Scalars['Date']>
  leaveType?: InputMaybe<LeaveApplicationTypeEnum[]>
  limit?: InputMaybe<Scalars['Int']>
  managerId?: InputMaybe<Scalars['ID'][]>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<SortEnum[]>
  sortBy?: InputMaybe<LeaveSortByEnum[]>
  startActiveDate?: InputMaybe<Scalars['Date']>
  startDate?: InputMaybe<Scalars['Date']>
  status?: InputMaybe<LeaveApplicationStatusEnum[]>
  submittedEndDateTo?: InputMaybe<Scalars['Date']>
  submittedStartDateTo?: InputMaybe<Scalars['Date']>
}

export enum LeaveApplicationStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  WITHDRAWN = 'WITHDRAWN'
}

export enum LeaveApplicationTypeEnum {
  AL = 'AL',
  HL = 'HL',
  NPL = 'NPL',
  OPL = 'OPL',
  SL = 'SL',
  WFH = 'WFH'
}

export enum LeaveSortByEnum {
  CREATED_AT = 'created_at',
  DAYS_APPLIED = 'days_applied',
  EMPLOYEE_DEPARTMENT = 'employee_department',
  EMPLOYEE_FIRST_NAME = 'employee_first_name',
  EMPLOYEE_LAST_NAME = 'employee_last_name',
  END_DATE = 'end_date',
  LEAVE_TYPE = 'leave_type',
  START_DATE = 'start_date',
  UPDATED_AT = 'updated_at'
}

export type ManagerUser = {
  __typename?: 'ManagerUser'
  email: Scalars['String']
  employeeId: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type: UserTypeEnum
}

export type MasterContract = {
  __typename?: 'MasterContract'
  annualLeave: Scalars['Int']
  contractType: ContractTypeEnum
  country: CountryEnum
  currency: CurrencyEnum
  hospitalLeave: Scalars['Int']
  id: Scalars['ID']
  payrollPeriod: PayrollPeriodEnum
  publicHolidaySchedule: CountryEnum
  sickLeave: Scalars['Int']
}

export type MasterContractsByFilters = {
  __typename?: 'MasterContractsByFilters'
  count: Scalars['Int']
  masterContracts: MasterContract[]
}

export type MasterContractsFilter = {
  contractType?: InputMaybe<ContractTypeEnum>
  country?: InputMaybe<CountryEnum>
}

export type Mutation = {
  __typename?: 'Mutation'
  applyPayment?: Maybe<ApplyPaymentPayload>
  applyPaymentCsv?: Maybe<ApplyPaymentCsvPayload>
  approveLeaveApplication?: Maybe<ApproveLeaveApplicationPayload>
  confirmPayroll?: Maybe<ConfirmPayrollPayload>
  confirmPayrollBatch?: Maybe<ConfirmPayrollBatchPayload>
  createContract?: Maybe<CreateContractPayload>
  createEmployee?: Maybe<CreateEmployeePayload>
  createLeaveApplication?: Maybe<CreateLeaveApplicationPayload>
  createPayrollAdjustment?: Maybe<CreatePayrollAdjustmentPayload>
  deleteContractFiles?: Maybe<DeleteContractFilesPayload>
  deleteEmployee?: Maybe<DeleteEmployeePayload>
  deleteLeaveApplication?: Maybe<DeleteLeaveApplicationPayload>
  deleteLeaveApplicationFiles?: Maybe<DeleteLeaveApplicationFilesPayload>
  deletePayrollAdjustment?: Maybe<DeletePayrollAdjustmentPayload>
  generateFinalPayroll?: Maybe<GenerateFinalPayrollPayload>
  generatePayrollsCsv?: Maybe<GeneratePayrollsCsvPayload>
  readNotification?: Maybe<ReadNotificationPayload>
  regeneratePayroll?: Maybe<RegeneratePayrollPayload>
  rejectLeaveApplication?: Maybe<RejectLeaveApplicationPayload>
  resetPassword?: Maybe<ResetPasswordPayload>
  sendResetPassword?: Maybe<SendResetPasswordPayload>
  signIn?: Maybe<SignInPayload>
  signOut?: Maybe<SignOutPayload>
  terminateEmployee?: Maybe<TerminateEmployeePayload>
  updateContract?: Maybe<UpdateContractPayload>
  updateEmployee?: Maybe<UpdateEmployeePayload>
  /** Updates current installation for the user */
  updateInstallation?: Maybe<UpdateInstallationPayload>
  updateLeaveApplication?: Maybe<UpdateLeaveApplicationPayload>
  updatePayrollAdjustment?: Maybe<UpdatePayrollAdjustmentPayload>
  updateProbationStatus?: Maybe<UpdateProbationStatusPayload>
  updateSalary?: Maybe<UpdateSalaryPayload>
  /** Updates viewer profile */
  updateViewer?: Maybe<UpdateViewerPayload>
  uploadContractFiles?: Maybe<UploadContractFilesPayload>
  uploadLeaveApplicationFiles?: Maybe<UploadLeaveApplicationFilesPayload>
  withdrawLeaveRequest?: Maybe<WithdrawLeaveRequestPayload>
}

export type MutationApplyPaymentArgs = {
  input: ApplyPaymentInput
}

export type MutationApplyPaymentCsvArgs = {
  input: ApplyPaymentCsvInput
}

export type MutationApproveLeaveApplicationArgs = {
  input: ApproveLeaveApplicationInput
}

export type MutationConfirmPayrollArgs = {
  input: ConfirmPayrollInput
}

export type MutationConfirmPayrollBatchArgs = {
  input: ConfirmPayrollBatchInput
}

export type MutationCreateContractArgs = {
  input: CreateContractInput
}

export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput
}

export type MutationCreateLeaveApplicationArgs = {
  input: CreateLeaveApplicationInput
}

export type MutationCreatePayrollAdjustmentArgs = {
  input: CreatePayrollAdjustmentInput
}

export type MutationDeleteContractFilesArgs = {
  input: DeleteContractFilesInput
}

export type MutationDeleteEmployeeArgs = {
  input: DeleteEmployeeInput
}

export type MutationDeleteLeaveApplicationArgs = {
  input: DeleteLeaveApplicationInput
}

export type MutationDeleteLeaveApplicationFilesArgs = {
  input: DeleteLeaveApplicationFilesInput
}

export type MutationDeletePayrollAdjustmentArgs = {
  input: DeletePayrollAdjustmentInput
}

export type MutationGenerateFinalPayrollArgs = {
  input: GenerateFinalPayrollInput
}

export type MutationGeneratePayrollsCsvArgs = {
  input: GeneratePayrollsCsvInput
}

export type MutationReadNotificationArgs = {
  input: ReadNotificationInput
}

export type MutationRegeneratePayrollArgs = {
  input: RegeneratePayrollInput
}

export type MutationRejectLeaveApplicationArgs = {
  input: RejectLeaveApplicationInput
}

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput
}

export type MutationSendResetPasswordArgs = {
  input: SendResetPasswordInput
}

export type MutationSignInArgs = {
  input: SignInInput
}

export type MutationSignOutArgs = {
  input: SignOutInput
}

export type MutationTerminateEmployeeArgs = {
  input: TerminateEmployeeInput
}

export type MutationUpdateContractArgs = {
  input: UpdateContractInput
}

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput
}

export type MutationUpdateInstallationArgs = {
  input: UpdateInstallationInput
}

export type MutationUpdateLeaveApplicationArgs = {
  input: UpdateLeaveApplicationInput
}

export type MutationUpdatePayrollAdjustmentArgs = {
  input: UpdatePayrollAdjustmentInput
}

export type MutationUpdateProbationStatusArgs = {
  input: UpdateProbationStatusInput
}

export type MutationUpdateSalaryArgs = {
  input: UpdateSalaryInput
}

export type MutationUpdateViewerArgs = {
  input: UpdateViewerInput
}

export type MutationUploadContractFilesArgs = {
  input: UploadContractFilesInput
}

export type MutationUploadLeaveApplicationFilesArgs = {
  input: UploadLeaveApplicationFilesInput
}

export type MutationWithdrawLeaveRequestArgs = {
  input: WithdrawLeaveRequestInput
}

export enum NationalityEnum {
  AUSTRALIAN = 'Australian',
  BANGLADESHI = 'Bangladeshi',
  BURMESE = 'Burmese',
  CAMBODIAN = 'Cambodian',
  CHINESE = 'Chinese',
  INDIAN = 'Indian',
  INDONESIAN = 'Indonesian',
  MALAYSIAN = 'Malaysian',
  PHILIPPINES = 'Philippines',
  SINGAPOREAN = 'Singaporean',
  SRILANKAN = 'SriLankan',
  THAI = 'Thai'
}

export type NotFoundProblem = {
  __typename?: 'NotFoundProblem'
  entity: Scalars['String']
  message: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  createdAt: Scalars['Date']
  id: Scalars['ID']
  message: Scalars['String']
  read: Scalars['Boolean']
  updatedAt: Scalars['Date']
}

export type OffboardingFilterInput = {
  limit?: InputMaybe<Scalars['Int']>
  managerId?: InputMaybe<Scalars['ID']>
  offset?: InputMaybe<Scalars['Int']>
  search?: InputMaybe<Scalars['String']>
  sort?: InputMaybe<SortEnum[]>
  sortBy?: InputMaybe<WorkerSortByEnum[]>
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>
}

export type Payroll = {
  __typename?: 'Payroll'
  amount: Scalars['Float']
  contract: Contract
  employee: Employee
  fromDate: Scalars['Date']
  id: Scalars['ID']
  paidAmount: Scalars['Float']
  paymentStatus: PayrollPaymentStatusEnum
  payrollPayments?: Maybe<PayrollPayment[]>
  payslips: Payslip[]
  status: PayrollStatusEnum
  toDate: Scalars['Date']
}

export type PayrollAdjustment = {
  __typename?: 'PayrollAdjustment'
  accountableForTax: Scalars['Boolean']
  adjustmentType: PayrollAdjustmentTypeEnum
  amount: Scalars['Float']
  currency: CurrencyEnum
  description: Scalars['String']
  employeeId: Scalars['ID']
  endDate: Scalars['String']
  id: Scalars['ID']
  payrollId?: Maybe<Scalars['ID']>
  startDate: Scalars['String']
}

export type PayrollAdjustmentByFilters = {
  __typename?: 'PayrollAdjustmentByFilters'
  count: Scalars['Int']
  payrollAdjustments: PayrollAdjustment[]
}

export type PayrollAdjustmentByFiltersInput = {
  adjustmentType?: InputMaybe<PayrollAdjustmentTypeEnum>
  employeeId?: InputMaybe<Scalars['ID'][]>
  endDate?: InputMaybe<Scalars['Date']>
  endDateForm?: InputMaybe<Scalars['Date']>
  endDateTo?: InputMaybe<Scalars['Date']>
  id?: InputMaybe<Scalars['ID'][]>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['Date']>
  startDateFrom?: InputMaybe<Scalars['Date']>
  startDateTo?: InputMaybe<Scalars['Date']>
}

export enum PayrollAdjustmentTypeEnum {
  ADDITION = 'ADDITION',
  BONUS = 'BONUS',
  DEDUCTION = 'DEDUCTION',
  OVERTIME = 'OVERTIME'
}

export type PayrollByFilters = {
  __typename?: 'PayrollByFilters'
  count: Scalars['Int']
  payrolls: Payroll[]
}

export type PayrollByFiltersInput = {
  employeeId?: InputMaybe<Scalars['ID'][]>
  endDateForm?: InputMaybe<Scalars['Date']>
  endDateTo?: InputMaybe<Scalars['Date']>
  fromDate?: InputMaybe<Scalars['Date']>
  id?: InputMaybe<Scalars['ID'][]>
  limit?: InputMaybe<Scalars['Int']>
  nationality?: InputMaybe<NationalityEnum>
  offset?: InputMaybe<Scalars['Int']>
  paymentStatus?: InputMaybe<PayrollPaymentStatusEnum>
  sort?: InputMaybe<SortEnum[]>
  sortBy?: InputMaybe<PayrollSortByEnum[]>
  startDateFrom?: InputMaybe<Scalars['Date']>
  startDateTo?: InputMaybe<Scalars['Date']>
  status?: InputMaybe<PayrollStatusEnum>
  toDate?: InputMaybe<Scalars['Date']>
}

export type PayrollPayment = {
  __typename?: 'PayrollPayment'
  amount: Scalars['Float']
  date: Scalars['Date']
  id: Scalars['ID']
  payrollId: Scalars['ID']
  reason?: Maybe<Scalars['String']>
  reference?: Maybe<Scalars['String']>
  transferMethod: PayrollPaymentTransferMethodsEnum
}

export enum PayrollPaymentStatusEnum {
  OVERPAID = 'OVERPAID',
  PAID = 'PAID',
  UNDERPAID = 'UNDERPAID',
  UNPAID = 'UNPAID'
}

export enum PayrollPaymentTransferMethodsEnum {
  BANK_TRANSFER = 'BANK_TRANSFER',
  CASH = 'CASH',
  CHEQUE = 'CHEQUE'
}

export enum PayrollSortByEnum {
  AMOUNT = 'amount',
  CREATED_AT = 'created_at',
  EMPLOYEE_DEPARTMENT = 'employee_department',
  EMPLOYEE_FIRST_NAME = 'employee_first_name',
  EMPLOYEE_FULL_NAME_ON_ID = 'employee_full_name_on_id',
  EMPLOYEE_LAST_NAME = 'employee_last_name',
  FROM_DATE = 'from_date',
  PAID_AMOUNT = 'paid_amount',
  PAYMENT_STATUS = 'payment_status',
  STATUS = 'status',
  TO_DATE = 'to_date',
  UPDATED_AT = 'updated_at'
}

export enum PayrollStatusEnum {
  CONFIRMED = 'CONFIRMED',
  NEW = 'NEW'
}

export type Payslip = {
  __typename?: 'Payslip'
  allowance: Scalars['Float']
  basicPay: Scalars['Float']
  bonus: Scalars['Float']
  createdAt: Scalars['Date']
  deduction: Scalars['Float']
  gross: Scalars['Float']
  id: Scalars['ID']
  nett: Scalars['Float']
  overtime: Scalars['Float']
  payrollId: Scalars['ID']
  payslipItems: PayslipItem[]
  status: PayslipStatusEnum
  updatedAt: Scalars['Date']
}

export type PayslipItem = {
  __typename?: 'PayslipItem'
  accountableForTax: Scalars['Boolean']
  amount: Scalars['Float']
  contractId: Scalars['ID']
  createdAt: Scalars['Date']
  description: Scalars['String']
  id: Scalars['ID']
  itemType: PayslipItemTypeEnum
  payrollCyclePeriodId: Scalars['ID']
  payslipId: Scalars['ID']
  status: PayslipItemStatusEnum
  updatedAt: Scalars['Date']
}

export enum PayslipItemStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED'
}

export enum PayslipItemTypeEnum {
  ALLOWANCE = 'ALLOWANCE',
  BASIC_PAY = 'BASIC_PAY',
  BONUS = 'BONUS',
  DEDUCTION = 'DEDUCTION',
  EMPLOYER_CONTRIBUTION = 'EMPLOYER_CONTRIBUTION',
  OVERTIME = 'OVERTIME'
}

export enum PayslipStatusEnum {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  NEW = 'NEW'
}

export type Ping = {
  __typename?: 'Ping'
  /** A succesfull ping will pong! */
  ping: Scalars['Boolean']
}

/** The query root of this schema */
export type Query = {
  __typename?: 'Query'
  allManagers: UserConnection
  completedOffboardings: EmployeeByFilters
  contractByFilters: ContractsByFilters
  contractById: Contract
  employeeByFilters: EmployeeByFilters
  employeeById: Employee
  /** Simple healthcheck to check that server is responding */
  healthcheck?: Maybe<Ping>
  leaveApplicationByFilters: LeaveApplicationByFilters
  masterContractsByFilters: MasterContractsByFilters
  notificationsByUser: Notification[]
  payrollAdjustmentByFilters: PayrollAdjustmentByFilters
  payrollByFilters: PayrollByFilters
  payrollById: Payroll
  uncompletedOffboardings: EmployeeByFilters
  /** Find user by given id */
  userById?: Maybe<AdminUser>
  /** All users */
  users?: Maybe<UserConnection>
  /** Viewer (current user) */
  viewer?: Maybe<Viewer>
}

/** The query root of this schema */
export type QueryAllManagersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** The query root of this schema */
export type QueryCompletedOffboardingsArgs = {
  filters?: InputMaybe<OffboardingFilterInput>
}

/** The query root of this schema */
export type QueryContractByFiltersArgs = {
  filters?: InputMaybe<ContractsByFilter>
}

/** The query root of this schema */
export type QueryContractByIdArgs = {
  id: Scalars['ID']
}

/** The query root of this schema */
export type QueryEmployeeByFiltersArgs = {
  filters?: InputMaybe<EmployeeByFiltersInput>
}

/** The query root of this schema */
export type QueryEmployeeByIdArgs = {
  id: Scalars['ID']
}

/** The query root of this schema */
export type QueryLeaveApplicationByFiltersArgs = {
  filters?: InputMaybe<LeaveApplicationByFiltersInput>
}

/** The query root of this schema */
export type QueryMasterContractsByFiltersArgs = {
  filters?: InputMaybe<MasterContractsFilter>
}

/** The query root of this schema */
export type QueryPayrollAdjustmentByFiltersArgs = {
  filters?: InputMaybe<PayrollAdjustmentByFiltersInput>
}

/** The query root of this schema */
export type QueryPayrollByFiltersArgs = {
  filters?: InputMaybe<PayrollByFiltersInput>
}

/** The query root of this schema */
export type QueryPayrollByIdArgs = {
  id: Scalars['ID']
}

/** The query root of this schema */
export type QueryUncompletedOffboardingsArgs = {
  filters?: InputMaybe<OffboardingFilterInput>
}

/** The query root of this schema */
export type QueryUserByIdArgs = {
  id: Scalars['ID']
}

/** The query root of this schema */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>
  before?: InputMaybe<Scalars['String']>
  first?: InputMaybe<Scalars['Int']>
  last?: InputMaybe<Scalars['Int']>
}

/** return type for saved file */
export type SavedFile = {
  __typename?: 'SavedFile'
  createdAt: Scalars['DateTime']
  downloadUrl: Scalars['String']
  filename: Scalars['String']
  id: Scalars['String']
  key: Scalars['String']
}

export type SendResetPasswordResult = NotFoundProblem

/** Result of success sign in */
export type SignInOk = {
  __typename?: 'SignInOk'
  jwt: Scalars['String']
  jwtExpiry: Scalars['Int']
  refreshToken: Scalars['String']
  viewer: Viewer
}

export enum SortEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type StaffUser = {
  __typename?: 'StaffUser'
  email: Scalars['String']
  employeeId: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type: UserTypeEnum
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export type SuperAdminUser = {
  __typename?: 'SuperAdminUser'
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type: UserTypeEnum
}

/** Possible user types */
export type User = AdminUser | ManagerUser | StaffUser | SuperAdminUser

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection'
  /** A list of edges. */
  edges?: Maybe<Maybe<UserEdge>[]>
  /** A list of nodes. */
  nodes?: Maybe<Maybe<User>[]>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']
  /** The item at the end of the edge. */
  node?: Maybe<User>
}

export enum UserTypeEnum {
  ADMIN = 'Admin',
  MANAGER = 'Manager',
  STAFF = 'Staff',
  SUPERADMIN = 'SuperAdmin'
}

export type ValidationError = {
  __typename?: 'ValidationError'
  field: Scalars['String']
  messages: Scalars['String'][]
}

/** Happen when arguments in input is not valid */
export type ValidationProblem = {
  __typename?: 'ValidationProblem'
  entity: Scalars['String']
  errors?: Maybe<ValidationError[]>
  message: Scalars['String']
}

export type Viewer = {
  __typename?: 'Viewer'
  createdAt?: Maybe<Scalars['DateTime']>
  /** Current user */
  currentUser?: Maybe<User>
  id: Scalars['ID']
  isSafelisted: Scalars['Boolean']
  updatedAt?: Maybe<Scalars['DateTime']>
}

/** Base user attributs */
export type ViewerDataInput = {
  avatarUrl?: InputMaybe<Scalars['String']>
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export enum WorkerSortByEnum {
  CONTACT_NUMBER = 'contact_number',
  CREATED_AT = 'created_at',
  DEPARTMENT = 'department',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  NATIONALITY = 'nationality',
  STATUS = 'status',
  UPDATED_AT = 'updated_at',
  WORKER_RATING = 'worker_rating'
}

/** Autogenerated input type of applyPaymentCsv */
export type ApplyPaymentCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  /** Bas64 encoded csv file as a string */
  fileString: Scalars['String']
}

/** Autogenerated return type of applyPaymentCsv */
export type ApplyPaymentCsvPayload = {
  __typename?: 'applyPaymentCsvPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  errors?: Maybe<CsvErrors[]>
  result: Scalars['Boolean']
}

/** Autogenerated input type of applyPayment */
export type ApplyPaymentInput = {
  amount: Scalars['Float']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  date: Scalars['Date']
  id: Scalars['ID']
  reason?: InputMaybe<Scalars['String']>
  reference?: InputMaybe<Scalars['String']>
  transferMethod: PayrollPaymentTransferMethodsEnum
}

/** Autogenerated return type of applyPayment */
export type ApplyPaymentPayload = {
  __typename?: 'applyPaymentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payroll: Payroll
}

/** Autogenerated input type of approveLeaveApplication */
export type ApproveLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of approveLeaveApplication */
export type ApproveLeaveApplicationPayload = {
  __typename?: 'approveLeaveApplicationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  leaveApplication: LeaveApplication
}

/** Autogenerated input type of confirmPayrollBatch */
export type ConfirmPayrollBatchInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID'][]
}

/** Autogenerated return type of confirmPayrollBatch */
export type ConfirmPayrollBatchPayload = {
  __typename?: 'confirmPayrollBatchPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payrolls: Payroll[]
}

/** Autogenerated input type of confirmPayroll */
export type ConfirmPayrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of confirmPayroll */
export type ConfirmPayrollPayload = {
  __typename?: 'confirmPayrollPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payroll: Payroll
}

export enum ContractTypeEnum {
  CONTRACT = 'CONTRACT',
  FULL_TIME = 'FULL_TIME',
  SHIFT = 'SHIFT'
}

export enum CountryEnum {
  ID = 'ID',
  PH = 'PH',
  SG = 'SG'
}

/** Autogenerated input type of createContract */
export type CreateContractInput = {
  allowance?: InputMaybe<Scalars['Int']>
  basicSalary: Scalars['Int']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  contractDays?: InputMaybe<ContractDaysInput[]>
  contractFiles?: InputMaybe<Scalars['UploadedFile'][]>
  contractShiftSchedule?: InputMaybe<ContractShiftScheduleInput>
  employeeId: Scalars['ID']
  employmentLevel: EmploymentLevelEnum
  endDate?: InputMaybe<Scalars['Date']>
  entitledToCommission?: InputMaybe<Scalars['Boolean']>
  masterContractId: Scalars['ID']
  nonTaxableAllowance?: InputMaybe<Scalars['Int']>
  probationPeriod?: InputMaybe<Scalars['Int']>
  startDate: Scalars['Date']
}

/** Autogenerated return type of createContract */
export type CreateContractPayload = {
  __typename?: 'createContractPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  contract: Contract
}

/** Autogenerated input type of createEmployee */
export type CreateEmployeeInput = {
  address: Scalars['String']
  avatarUrl?: InputMaybe<Scalars['String']>
  bankAccountNumber: Scalars['String']
  bankName: BankNameEnum
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  dateOfBirth: Scalars['Date']
  department: EmployeeDepartmentEnum
  email: Scalars['String']
  emergencyContactName?: InputMaybe<Scalars['String']>
  emergencyContactNumber?: InputMaybe<Scalars['String']>
  emergencyContactRelationship?: InputMaybe<EmployeeRelationshipEnum>
  firstName: Scalars['String']
  fullNameOnId: Scalars['String']
  idCardNumber: Scalars['String']
  jobTitle: Scalars['String']
  lastName: Scalars['String']
  managerId?: InputMaybe<Scalars['ID']>
  maritalStatus?: InputMaybe<EmployeeMaritalStatusEnum>
  nationality: NationalityEnum
  numberOfChildren?: InputMaybe<Scalars['Int']>
  phoneNumber: Scalars['String']
  religion?: InputMaybe<EmployeeReligionEnum>
  taxType?: InputMaybe<EmployeeTaxTypeEnum>
  workEmail: Scalars['String']
}

/** Autogenerated return type of createEmployee */
export type CreateEmployeePayload = {
  __typename?: 'createEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  employee: Employee
}

/** Autogenerated input type of createLeaveApplication */
export type CreateLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  leaveApplicationFiles?: InputMaybe<Scalars['UploadedFile'][]>
  leaveType: LeaveApplicationTypeEnum
  reason?: InputMaybe<Scalars['String']>
  startDate: Scalars['Date']
}

/** Autogenerated return type of createLeaveApplication */
export type CreateLeaveApplicationPayload = {
  __typename?: 'createLeaveApplicationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  leaveApplication: LeaveApplication
}

/** Autogenerated input type of createPayrollAdjustment */
export type CreatePayrollAdjustmentInput = {
  accountableForTax?: InputMaybe<Scalars['Boolean']>
  adjustmentType: PayrollAdjustmentTypeEnum
  amount: Scalars['Float']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
  startDate: Scalars['Date']
}

/** Autogenerated return type of createPayrollAdjustment */
export type CreatePayrollAdjustmentPayload = {
  __typename?: 'createPayrollAdjustmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payrollAdjustment: PayrollAdjustment
}

export enum CurrencyEnum {
  IDR = 'IDR',
  PHP = 'PHP',
  SGD = 'SGD'
}

/** Autogenerated input type of deleteContractFiles */
export type DeleteContractFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  contractId: Scalars['ID']
  idFiles: Scalars['ID'][]
}

/** Autogenerated return type of deleteContractFiles */
export type DeleteContractFilesPayload = {
  __typename?: 'deleteContractFilesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

/** Autogenerated input type of deleteEmployee */
export type DeleteEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of deleteEmployee */
export type DeleteEmployeePayload = {
  __typename?: 'deleteEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

/** Autogenerated input type of deleteLeaveApplicationFiles */
export type DeleteLeaveApplicationFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  idFiles: Scalars['ID'][]
  leaveId: Scalars['ID']
}

/** Autogenerated return type of deleteLeaveApplicationFiles */
export type DeleteLeaveApplicationFilesPayload = {
  __typename?: 'deleteLeaveApplicationFilesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

/** Autogenerated input type of deleteLeaveApplication */
export type DeleteLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of deleteLeaveApplication */
export type DeleteLeaveApplicationPayload = {
  __typename?: 'deleteLeaveApplicationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

/** Autogenerated input type of deletePayrollAdjustment */
export type DeletePayrollAdjustmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of deletePayrollAdjustment */
export type DeletePayrollAdjustmentPayload = {
  __typename?: 'deletePayrollAdjustmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result: Scalars['Boolean']
}

export enum EmploymentLevelEnum {
  ASSOCIATE_1 = 'ASSOCIATE_1',
  ASSOCIATE_2 = 'ASSOCIATE_2',
  ASSOCIATE_3 = 'ASSOCIATE_3',
  EXECUTIVE_1 = 'EXECUTIVE_1',
  EXECUTIVE_2 = 'EXECUTIVE_2',
  EXECUTIVE_3 = 'EXECUTIVE_3',
  JUNIOR_EXECUTIVE_1 = 'JUNIOR_EXECUTIVE_1',
  JUNIOR_EXECUTIVE_2 = 'JUNIOR_EXECUTIVE_2',
  MANAGER_1 = 'MANAGER_1',
  MANAGER_2 = 'MANAGER_2',
  MANAGER_3 = 'MANAGER_3'
}

/** Autogenerated input type of generateFinalPayroll */
export type GenerateFinalPayrollInput = {
  billingDate: Scalars['Date']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  employeeId: Scalars['ID']
}

/** Autogenerated return type of generateFinalPayroll */
export type GenerateFinalPayrollPayload = {
  __typename?: 'generateFinalPayrollPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payroll: Payroll
}

/** Autogenerated input type of generatePayrollsCsv */
export type GeneratePayrollsCsvInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  monthEndDate?: InputMaybe<Scalars['Date']>
  monthStartDate: Scalars['Date']
  paymentStatus?: InputMaybe<PayrollPaymentStatusEnum[]>
  status: PayrollStatusEnum
}

/** Autogenerated return type of generatePayrollsCsv */
export type GeneratePayrollsCsvPayload = {
  __typename?: 'generatePayrollsCsvPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  /** Bas64 encoded csv file as a string */
  fileString: Scalars['String']
}

export enum PayrollPeriodEnum {
  MONTHLY = 'MONTHLY',
  TWICE_A_MONTH = 'TWICE_A_MONTH'
}

export enum ProbationStatusEnum {
  FAILED = 'FAILED',
  ON_GOING = 'ON_GOING',
  PASSED = 'PASSED'
}

/** Autogenerated input type of readNotification */
export type ReadNotificationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  notificationId: Scalars['ID']
}

/** Autogenerated return type of readNotification */
export type ReadNotificationPayload = {
  __typename?: 'readNotificationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  notification: Notification
}

/** Autogenerated input type of regeneratePayroll */
export type RegeneratePayrollInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of regeneratePayroll */
export type RegeneratePayrollPayload = {
  __typename?: 'regeneratePayrollPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payroll: Payroll
}

/** Autogenerated input type of rejectLeaveApplication */
export type RejectLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
}

/** Autogenerated return type of rejectLeaveApplication */
export type RejectLeaveApplicationPayload = {
  __typename?: 'rejectLeaveApplicationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  leaveApplication: LeaveApplication
}

/** Autogenerated input type of resetPassword */
export type ResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  newPassword: Scalars['String']
  pinNumber: Scalars['String']
}

/** Autogenerated return type of resetPassword */
export type ResetPasswordPayload = {
  __typename?: 'resetPasswordPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of sendResetPassword */
export type SendResetPasswordInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
}

/** Autogenerated return type of sendResetPassword */
export type SendResetPasswordPayload = {
  __typename?: 'sendResetPasswordPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result?: Maybe<SendResetPasswordResult>
}

/** Autogenerated input type of signIn */
export type SignInInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  email: Scalars['String']
  installation?: InputMaybe<InstallationInput>
  password: Scalars['String']
}

/** Autogenerated return type of signIn */
export type SignInPayload = {
  __typename?: 'signInPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  result: SignInResult
}

/** Result of signIn mutation */
export type SignInResult = SignInOk | ValidationProblem

/** Autogenerated input type of signOut */
export type SignOutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of signOut */
export type SignOutPayload = {
  __typename?: 'signOutPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of terminateEmployee */
export type TerminateEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  employeeId: Scalars['ID']
  endDate: Scalars['Date']
}

/** Autogenerated return type of terminateEmployee */
export type TerminateEmployeePayload = {
  __typename?: 'terminateEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  employee: Employee
}

/** Autogenerated input type of updateContract */
export type UpdateContractInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  contractDays?: InputMaybe<ContractDaysInput[]>
  contractShiftSchedule?: InputMaybe<ContractShiftScheduleInput>
  endDate?: InputMaybe<Scalars['Date']>
  id: Scalars['ID']
  probationPeriod?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['Date']>
}

/** Autogenerated return type of updateContract */
export type UpdateContractPayload = {
  __typename?: 'updateContractPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  contract: Contract
}

/** Autogenerated input type of updateEmployee */
export type UpdateEmployeeInput = {
  address?: InputMaybe<Scalars['String']>
  avatarUrl?: InputMaybe<Scalars['String']>
  bankAccountNumber?: InputMaybe<Scalars['String']>
  bankName?: InputMaybe<BankNameEnum>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  dateOfBirth?: InputMaybe<Scalars['Date']>
  department?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
  emergencyContactName?: InputMaybe<Scalars['String']>
  emergencyContactNumber?: InputMaybe<Scalars['String']>
  emergencyContactRelationship?: InputMaybe<EmployeeRelationshipEnum>
  firstName?: InputMaybe<Scalars['String']>
  fullNameOnId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  idCardNumber?: InputMaybe<Scalars['String']>
  jobTitle?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  managerId?: InputMaybe<Scalars['ID']>
  maritalStatus?: InputMaybe<EmployeeMaritalStatusEnum>
  nationality?: InputMaybe<NationalityEnum>
  numberOfChildren?: InputMaybe<Scalars['Int']>
  phoneNumber?: InputMaybe<Scalars['String']>
  religion?: InputMaybe<Scalars['String']>
  taxType?: InputMaybe<EmployeeTaxTypeEnum>
  workEmail?: InputMaybe<Scalars['String']>
}

/** Autogenerated return type of updateEmployee */
export type UpdateEmployeePayload = {
  __typename?: 'updateEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  employee: Employee
}

/** Autogenerated input type of updateInstallation */
export type UpdateInstallationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  installation: InstallationInput
}

/** Autogenerated return type of updateInstallation */
export type UpdateInstallationPayload = {
  __typename?: 'updateInstallationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

/** Autogenerated input type of updateLeaveApplication */
export type UpdateLeaveApplicationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  endDate: Scalars['Date']
  id: Scalars['ID']
  leaveApplicationFiles?: InputMaybe<Scalars['UploadedFile'][]>
  reason?: InputMaybe<Scalars['String']>
  startDate: Scalars['Date']
}

/** Autogenerated return type of updateLeaveApplication */
export type UpdateLeaveApplicationPayload = {
  __typename?: 'updateLeaveApplicationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  leaveApplication: LeaveApplication
}

/** Autogenerated input type of updatePayrollAdjustment */
export type UpdatePayrollAdjustmentInput = {
  accountableForTax?: InputMaybe<Scalars['Boolean']>
  adjustmentType: PayrollAdjustmentTypeEnum
  amount: Scalars['Float']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  description: Scalars['String']
  endDate?: InputMaybe<Scalars['Date']>
  id: Scalars['ID']
  startDate: Scalars['Date']
}

/** Autogenerated return type of updatePayrollAdjustment */
export type UpdatePayrollAdjustmentPayload = {
  __typename?: 'updatePayrollAdjustmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  payrollAdjustment: PayrollAdjustment
}

/** Autogenerated input type of updateProbationStatus */
export type UpdateProbationStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  probationStatus: ProbationStatusEnum
}

/** Autogenerated return type of updateProbationStatus */
export type UpdateProbationStatusPayload = {
  __typename?: 'updateProbationStatusPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  contract: Contract
}

/** Autogenerated input type of updateSalary */
export type UpdateSalaryInput = {
  allowance?: InputMaybe<Scalars['Int']>
  basicSalary: Scalars['Int']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  employmentLevel: EmploymentLevelEnum
  endDate?: InputMaybe<Scalars['Date']>
  entitledToCommission?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  nonTaxableAllowance?: InputMaybe<Scalars['Int']>
  startDate?: InputMaybe<Scalars['Date']>
}

/** Autogenerated return type of updateSalary */
export type UpdateSalaryPayload = {
  __typename?: 'updateSalaryPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  contract: Contract
}

/** Autogenerated input type of updateViewer */
export type UpdateViewerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  data: ViewerDataInput
}

/** Autogenerated return type of updateViewer */
export type UpdateViewerPayload = {
  __typename?: 'updateViewerPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  viewer: Viewer
}

/** Autogenerated input type of uploadContractFiles */
export type UploadContractFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  contractFiles: Scalars['UploadedFile'][]
  contractId: Scalars['ID']
}

/** Autogenerated return type of uploadContractFiles */
export type UploadContractFilesPayload = {
  __typename?: 'uploadContractFilesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  uploadedFiles: SavedFile[]
}

/** Autogenerated input type of uploadLeaveApplicationFiles */
export type UploadLeaveApplicationFilesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  leaveApplicationFiles: Scalars['UploadedFile'][]
  leaveId: Scalars['ID']
}

/** Autogenerated return type of uploadLeaveApplicationFiles */
export type UploadLeaveApplicationFilesPayload = {
  __typename?: 'uploadLeaveApplicationFilesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  uploadedFiles: SavedFile[]
}

/** Autogenerated input type of withdrawLeaveRequest */
export type WithdrawLeaveRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>
  leaveId: Scalars['ID']
}

/** Autogenerated return type of withdrawLeaveRequest */
export type WithdrawLeaveRequestPayload = {
  __typename?: 'withdrawLeaveRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>
  leaveApplication: LeaveApplication
}
