import { ApolloError } from 'apollo-boost'
import { ErrorResponse } from 'apollo-link-error'
import set from 'date-fns/set'

import { formatDate, parseDate } from './date'
import {
  EmployeeDepartmentEnum,
  EmployeeStatusEnum,
  LeaveApplicationByFiltersInput,
  LeaveApplicationTypeEnum
} from '../__generated__/schema'
import { PayslipItemListData } from '../components/employee/widget/PayslipViewerWidget'
import { development, production, staging } from '../config'

type Env = {
  env: string
  sentryDsn: string
}

export type AddressesValueOption = {
  id: string
  address: string
}

export function getEnv(): Env {
  switch (process.env.REACT_APP_ENV) {
    case development.env:
      return development
    case staging.env:
      return staging
    case production.env:
      return production
    default:
      return development
  }
}

export function isLocal(): boolean {
  return process.env.REACT_APP_ENV === 'local'
}

export const getErrorMessage = (
  err: ErrorResponse | ApolloError,
  fallback: string
): string =>
  err.graphQLErrors && err.graphQLErrors.length > 0
    ? err.graphQLErrors[0].message
    : err.networkError?.message ?? fallback

export const preventSilentSignin = (): void => {
  if (window.PasswordCredential || window.FederatedCredential) {
    navigator?.credentials?.preventSilentAccess()
  }
}

export const getAvatarLetter = (user: {
  firstName?: string
  lastName?: string
}): string =>
  [user.firstName, user.lastName]
    .map((name) => (name && name.length > 0 ? name[0] : ''))
    .join('')

export const getScrollBarWidth = (): number => {
  return window.innerWidth - document.documentElement.clientWidth
}

export const getCapitalCase = (word: string, spliter: string = '_'): string => {
  return word
    .toLocaleLowerCase()
    .split(spliter)
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

export const removeSpaces = (word: string): string => {
  return word.replace(/\s+/g, '')
}

export const getServiceTypeCode = (serviceType: string): string => {
  return serviceType
    .split('_')
    .map((s) => s[0])
    .join('')
}

export const getKeyByValue = (obj: any, value: string): string | undefined => {
  return Object.keys(obj).find((key) => obj[key] === value)
}

export const getVisitRangeUpcoming = (): {
  fromDateUpcoming: Date
  toDateUpcoming: Date
  customToDateUpcoming: Date
} => {
  const currentDate = new Date()
  const fromDateUpcoming = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    date: currentDate.getDate()
  })
  const toDateUpcoming = set(currentDate, {
    year: currentDate.getFullYear() + 1,
    month: 11,
    date: 31
  })
  const customToDateUpcoming = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 2,
    date: currentDate.getDate()
  })
  return {
    fromDateUpcoming,
    toDateUpcoming,
    customToDateUpcoming
  }
}
const { fromDateUpcoming, toDateUpcoming } = getVisitRangeUpcoming()

export const getVisitRangePast = (): {
  fromDatePast: Date
  customFromDatePast: Date
  toDatePast: Date
} => {
  const currentDate = new Date()
  const fromDatePast = set(currentDate, {
    year: currentDate.getFullYear() - 1,
    month: 0,
    date: 1
  })
  const customFromDatePast = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() - 2,
    date: currentDate.getDate()
  })
  const toDatePast = set(currentDate, {
    year: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    date: currentDate.getDate()
  })
  return {
    fromDatePast,
    toDatePast,
    customFromDatePast
  }
}
const { fromDatePast, toDatePast } = getVisitRangePast()

export const getVisitFromDate = (tabSelected: number) => {
  switch (tabSelected) {
    case 0:
      return formatDate(fromDateUpcoming)
    case 1:
      return formatDate(fromDatePast)
    default:
      return formatDate(fromDateUpcoming)
  }
}

export const getVisitToDate = (tabSelected: number) => {
  switch (tabSelected) {
    case 0:
      return formatDate(toDateUpcoming)
    case 1:
      return formatDate(toDatePast)
    default:
      return formatDate(toDateUpcoming)
  }
}

export function removeFileExtension(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, '')
}

export function addTimestampToFileName(
  file: File,
  extension: string,
  type: string
): File {
  return new File(
    [file],
    `${removeFileExtension(file.name)}_${new Date().valueOf()}.${extension}`,
    { type }
  )
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result) {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4))
        }
        resolve(encoded)
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

export function getBlobFromBase64(fileUrl: string): Blob {
  const fileContent = atob(fileUrl)
  return new Blob([fileContent], {
    type: 'data:application/octet-stream;base64'
  })
}

type EmployeeFirstLastName = {
  firstName: string
  lastName: string
}

export function formatEmployeeName(employee: EmployeeFirstLastName): string {
  const { firstName, lastName } = employee
  return `${firstName} ${lastName}`
}

export function formatWorkerFirstLastName(
  firstName: string,
  lastName: string
): string {
  return `${firstName} ${lastName}`
}

export function getFormatPhoneNumber(phoneNumber?: string): string {
  // we actually use this because there is a phoneNumber which is optional,
  // for optional the phoneNumber value will not be validated when the value is smaller than 3,
  // so here we assume any phoneNumber value smaller than three will not be processed to the body request backend
  if (phoneNumber && phoneNumber.length > 3) {
    const formatNumber = ('' + phoneNumber).replace(/[()\s]|[- ]/g, '')
    return formatNumber
  }
  return ''
}

export const getUnitNumber = (unitFloor?: string, apartmentNumber?: string) => {
  if (unitFloor === '' && apartmentNumber === '') return ''
  if (unitFloor === '') return `#NA-${apartmentNumber}`
  if (apartmentNumber === '') return `#${unitFloor}-NA`

  return `#${unitFloor}-${apartmentNumber}`
}

// Set 'any' to allow any data type address passing into this function
export const concatAddress = (address: any): string => {
  if (!address) {
    return '-'
  }

  const { fullAddress, unitNumber, postalCode } = address

  if (unitNumber) {
    return `${fullAddress} ${unitNumber} S${postalCode}`
  }

  return `${fullAddress} S${postalCode}`
}

export function getStatusFilter(
  statusFilter: string,
  managerFilter?: string | undefined
): { status?: EmployeeStatusEnum[]; managerId?: string[] } | null {
  if (!statusFilter && !managerFilter) {
    return null
  }
  const setEmployeesFilter: {
    status?: EmployeeStatusEnum[]
    managerId?: string[]
  } = {}

  if (statusFilter) {
    setEmployeesFilter['status'] = [statusFilter as EmployeeStatusEnum]
  }
  if (managerFilter) {
    setEmployeesFilter['managerId'] = [managerFilter]
  }

  return setEmployeesFilter
}

export const setUnitNumber = (
  outputType: 'unitFloor' | 'apartmentNumber',
  unitNumber: string
): string | undefined => {
  if (!unitNumber) {
    return ''
  }

  if (!unitNumber.includes('#')) {
    if (outputType === 'unitFloor') {
      return unitNumber
    }
    if (outputType === 'apartmentNumber') {
      return 'NA'
    }
  } else if (unitNumber.includes('#')) {
    const splitedUnitNumber = unitNumber.split('#')[1]
    if (splitedUnitNumber.includes('-')) {
      // #222-122
      const [unitFloor, apartmentNumber] = splitedUnitNumber.split('-')
      if (outputType === 'unitFloor') {
        return unitFloor
      }
      if (outputType === 'apartmentNumber') {
        return apartmentNumber
      }
    } else {
      // #222
      if (outputType === 'unitFloor') {
        return splitedUnitNumber
      }
      if (outputType === 'apartmentNumber') {
        return 'NA'
      }
    }
  }
}

export const getDefaultManagerFilter = (
  managerId: string | undefined,
  defaultId: string
): string => {
  if (managerId === 'ALL' || managerId === undefined) {
    return ''
  }

  if (managerId) {
    return managerId
  }

  return defaultId
}

export const getBtnTerminationLabel = (
  startDate: string,
  endDate: string | undefined
) => {
  const parseCurrentDate = new Date()
  const parseStartDate = parseDate(startDate)
  if (parseCurrentDate >= parseStartDate && endDate) {
    return 'Update Termination Date'
  }
  if (parseCurrentDate >= parseStartDate) {
    return 'Terminate'
  }
  return 'Update End Date'
}

export const offsetCountPagination = (
  page: number,
  rowsPerPage: number
): object => {
  return {
    offset: page > 0 ? page * rowsPerPage : 0,
    limit: rowsPerPage
  }
}

export const splitStringOnUppercase = (str: string) => {
  const findMatch = str.match(/[A-Z][a-z]+|[0-9]+/g)

  if (findMatch) {
    return findMatch.join(' ')
  } else {
    return str
  }
}

export const convertSnakeCaseToSentence = (str: string) => {
  return capitalizeAllFirstLetter(str.replaceAll('_', ' '))
}

export const capitalizeAllFirstLetter = (str: string) => {
  const arr = str.split(' ')

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }

  return arr.join(' ')
}

export const getTitleCase = (word: string): string => {
  return word
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export function formatDisplayPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) {
    return 'No phone number'
  }
  const listOfPhoneCode = [
    '+65',
    '+63',
    '+62',
    '+91',
    '+55',
    '+64',
    '+66',
    '+38',
    '+1',
    '+7'
  ]
  if (listOfPhoneCode.includes(phoneNumber.slice(0, 2))) {
    return phoneNumber
  } else if (phoneNumber.slice(0, 3) === '+65') {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
      3,
      7
    )}-${phoneNumber.slice(7, 11)}`
  } else {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
      3,
      7
    )} ${phoneNumber.slice(7, 11)} ${phoneNumber.slice(11)}`
  }
}

export function formatAmount(salary: string): string {
  const amount = salary.replace(/[,a-zA-Z]/g, '')
  const formattedAmount = parseFloat(amount).toFixed(2)
  return formattedAmount
}

export const splitAmountValue = (amount?: string): string => {
  if (!amount) {
    return '0'
  }

  return amount.split(' ').length > 1 ? amount.split(' ')[1] : amount
}

export const getPayslipAmountTotal = (
  amount: PayslipItemListData[]
): number => {
  const initialTotal = 0
  return amount
    ?.map((addition) => parseFloat(formatAmount(addition.amount)))
    .reduce((a, b) => a + b, initialTotal)
}

export const getFiltersLeaveApplication = (
  leaveTypeValue: string,
  departmentValue?: string
): LeaveApplicationByFiltersInput => {
  const filterValue: LeaveApplicationByFiltersInput = {}

  if (
    leaveTypeValue === 'ALL' &&
    (!departmentValue || departmentValue === 'ALL')
  ) {
    return filterValue
  }

  if (leaveTypeValue !== 'ALL') {
    filterValue['leaveType'] = [leaveTypeValue as LeaveApplicationTypeEnum]
  }

  if (departmentValue && departmentValue !== 'ALL') {
    filterValue['department'] = [departmentValue as EmployeeDepartmentEnum]
  }

  return filterValue
}
