import { ApolloProvider } from '@apollo/react-hooks/index'
import { SnackbarProvider } from 'notistack'
import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './context/authContext'
import RoutesElement from './route/RoutesElement'
import { apolloClient } from './services/apollo'

import './App.css'
import LoadingOverlay from './ui/LoadingOverlay'

const App: React.FC = () => {
  return (
    <Suspense fallback={<LoadingOverlay />}>
      <ApolloProvider client={apolloClient}>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <BrowserRouter>
              <RoutesElement />
            </BrowserRouter>
          </AuthProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </Suspense>
  )
}

export default App
