import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { PayrollsListData } from './payrollList/PayrollList.screen'
import { RootState } from '../../redux/slices'
import { PayslipListData } from '../employee/widget/PayslipViewerWidget'

type State = {
  showAdminPayrollConfirmation: boolean
  regenerateAdminPayroll: boolean
  generatePayment: boolean
  uploadPayment: boolean
  applyPayment: boolean
  showConfirmBatchPayrolls: boolean
  selectedPayrollIds: string[]
  payrollData: PayrollsListData | null
  payslipData: PayslipListData | null
}

export const initialState: State = {
  showAdminPayrollConfirmation: false,
  regenerateAdminPayroll: false,
  generatePayment: false,
  uploadPayment: false,
  applyPayment: false,
  showConfirmBatchPayrolls: false,
  selectedPayrollIds: [],
  payrollData: null,
  payslipData: null
}

const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    setShowAdminPayrollConfirmation(
      state,
      action: PayloadAction<{ showAdminPayrollConfirmation: boolean }>
    ) {
      state.showAdminPayrollConfirmation =
        action.payload.showAdminPayrollConfirmation
    },
    setRegenerateAdminPayroll(
      state,
      action: PayloadAction<{ regenerateAdminPayroll: boolean }>
    ) {
      state.regenerateAdminPayroll = action.payload.regenerateAdminPayroll
    },
    setGeneratePayment(
      state,
      action: PayloadAction<{ generatePayment: boolean }>
    ) {
      state.generatePayment = action.payload.generatePayment
    },
    setUploadPayment(state, action: PayloadAction<{ uploadPayment: boolean }>) {
      state.uploadPayment = action.payload.uploadPayment
    },
    setApplyPayment(state, action: PayloadAction<{ applyPayment: boolean }>) {
      state.applyPayment = action.payload.applyPayment
    },
    setPayrollData(
      state,
      action: PayloadAction<{ payrollData: PayrollsListData | null }>
    ) {
      state.payrollData = action.payload.payrollData
    },
    setPayslipData(
      state,
      action: PayloadAction<{ payslipData: PayslipListData | null }>
    ) {
      state.payslipData = action.payload.payslipData
    },
    setSelectedPayrollIds(
      state,
      action: PayloadAction<{
        selectedPayrollId: string
        isClearAllSelected?: boolean
        isClearSelected?: boolean
      }>
    ) {
      if (action.payload.isClearAllSelected) {
        state.selectedPayrollIds = []
      } else if (action.payload.isClearSelected) {
        state.selectedPayrollIds = state.selectedPayrollIds.filter(
          (id) => id !== action.payload.selectedPayrollId
        )
      } else {
        state.selectedPayrollIds = [
          ...state.selectedPayrollIds,
          action.payload.selectedPayrollId
        ]
      }
    },
    setShowConfirmPayrollBatch(
      state,
      action: PayloadAction<{ isConfirmPayrollBatch: boolean }>
    ) {
      state.showConfirmBatchPayrolls = action.payload.isConfirmPayrollBatch
    }
  }
})

export const showAdminPayrollConfirmationSelector = (
  state: RootState
): boolean => state.payrollReducer.showAdminPayrollConfirmation

export const regenerateAdminPayrollSelector = (state: RootState): boolean =>
  state.payrollReducer.regenerateAdminPayroll

export const generatePaymentSelector = (state: RootState): boolean =>
  state.payrollReducer.generatePayment

export const uploadPaymentSelector = (state: RootState): boolean =>
  state.payrollReducer.uploadPayment

export const applyPaymentSelector = (state: RootState): boolean =>
  state.payrollReducer.applyPayment

export const payrollDataSelector = (
  state: RootState
): PayrollsListData | null => state.payrollReducer.payrollData

export const payslipDataSelector = (state: RootState): PayslipListData | null =>
  state.payrollReducer.payslipData

export const selectedPayrollIdsSelector = (state: RootState): string[] =>
  state.payrollReducer.selectedPayrollIds

export const showConfirmPayrollsBatchSelector = (state: RootState): boolean =>
  state.payrollReducer.showConfirmBatchPayrolls

export const { name, actions: payrollActions, reducer } = payrollSlice
