import React, { lazy } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import { UserTypeEnum } from '../__generated__/schema'
import AppBar from '../components/appbar/Appbar'

const LoginContainer = lazy(() => import('../components/auth/Login.container'))

const ForgotPasswordContainer = lazy(
  () => import('../components/auth/forgotPassword/ForgotPassword.container')
)

const ResetPasswordContainer = lazy(
  () => import('../components/auth/resetPassword/ResetPassword.container')
)

const EmployeesListContainer = lazy(
  () => import('../components/employee/employeesList/EmployeesList.container')
)
const EmployeeDetail = lazy(
  () => import('../components/employee/employeeDetail/EmployeeDetail.container')
)

const Profile = lazy(
  () => import('../components/employee/employeeDetail/EmployeeDetail.container')
)
const LeaveList = lazy(
  () => import('../components/leave/leaveList/LeaveList.container')
)

const EmployeeContract = lazy(
  () =>
    import('../components/employee/employeeContract/EmployeeContract.container')
)

const PayrollContainer = lazy(
  () => import('../components/payroll/payrollList/PayrollLists.container')
)

const EmployeeLeaves = lazy(
  () =>
    import(
      '../components/employeeLeaves/employeeLeavesList/EmployeeLeavesList.container'
    )
)

// const EmployeeResignationList = lazy(
//   () =>
//     import(
//       '../components/employeeResignation/employeeResignationList/EmployeeResignationList.container'
//     )
// )

const RoutesElement: React.FC = () => {
  const onlyAdmin = [UserTypeEnum.ADMIN, UserTypeEnum.SUPERADMIN]

  return (
    <>
      <AppBar />
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/forgot-password" element={<ForgotPasswordContainer />} />
        <Route path="/reset-password" element={<ResetPasswordContainer />} />
        <Route
          key="profile"
          path="/profile"
          element={
            <PrivateRoute
              userType={[
                UserTypeEnum.STAFF,
                UserTypeEnum.ADMIN,
                UserTypeEnum.MANAGER
              ]}
            >
              <Profile isProfileScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees"
          element={
            <PrivateRoute userType={onlyAdmin}>
              <EmployeesListContainer />
            </PrivateRoute>
          }
        />
        <Route
          key="employee-detail"
          path="/employee/:id"
          element={
            <PrivateRoute userType={onlyAdmin}>
              <EmployeeDetail />
            </PrivateRoute>
          }
        />
        <Route
          key="contract-detail"
          path="/employee/:id/contract/:contractId"
          element={
            <PrivateRoute userType={onlyAdmin}>
              <EmployeeContract />
            </PrivateRoute>
          }
        />
        <Route
          key="contract-creation"
          path="/employee/:id/contract/new"
          element={
            <PrivateRoute userType={onlyAdmin}>
              <EmployeeContract />
            </PrivateRoute>
          }
        />
        <Route
          key="leave"
          path="/leave-requests"
          element={
            <PrivateRoute
              userType={[
                UserTypeEnum.MANAGER,
                UserTypeEnum.ADMIN,
                UserTypeEnum.SUPERADMIN
              ]}
            >
              <LeaveList />
            </PrivateRoute>
          }
        />
        <Route
          key="payrolls"
          path="/payrolls"
          element={
            <PrivateRoute userType={onlyAdmin}>
              <PayrollContainer />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee-leaves"
          element={
            <PrivateRoute
              userType={[
                UserTypeEnum.MANAGER,
                UserTypeEnum.STAFF,
                UserTypeEnum.ADMIN,
                UserTypeEnum.SUPERADMIN
              ]}
            >
              <EmployeeLeaves />
            </PrivateRoute>
          }
        />

        {/* Will add the 404 page */}
        <Route path="*" element={<>NOT FOUND</>} />
      </Routes>
    </>
  )
}

export default RoutesElement
