import { combineReducers } from '@reduxjs/toolkit'

import { reducer as appReducer } from './appSlice'
import { reducer as modalReducer } from './modalSlice'
import { reducer as employeeReducer } from '../../components/employee/employee.slice'
import { reducer as leaveReducer } from '../../components/leave/leave.slice'
import { reducer as payrollReducer } from '../../components/payroll/payroll.slice'

const rootReducer = combineReducers({
  appReducer,
  employeeReducer,
  modalReducer,
  leaveReducer,
  payrollReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
