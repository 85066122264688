import CircularProgress from '@luce/ui-kit/components/atom/CircularProgress'
import Box from '@luce/ui-kit/components/base/Box'
import React from 'react'

const Loader: React.FC<{ mt?: number }> = (props) => {
  const { mt = 2 } = props
  return (
    <Box display="flex" justifyContent="center" width="100%" mt={mt}>
      <CircularProgress />
    </Box>
  )
}

export default Loader
