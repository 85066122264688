import * as React from 'react'
import { Navigate } from 'react-router-dom'

import useAuth from '../hooks/AuthHook'

type Props = {
  userType: string[]
  children: React.ReactNode
}

// @ts-ignore
const PrivateRoute: React.FC<Props> = (props) => {
  const { userType, children } = props
  const { loadingSession, user } = useAuth() ?? {}
  const isAccessDenied = userType.includes(user?.type ?? '')

  if (!user) {
    return <Navigate to="login" />
  }

  if (loadingSession) {
    return null
  }

  if (!isAccessDenied) {
    return <Navigate to="/not-found" />
  }

  return children
}

export default PrivateRoute
